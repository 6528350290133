export default {
  methods: {
    syncImage (data, collection) {
      this.setupImages()
      const formData = new FormData()
      formData.append('file', data)
      formData.append('model', 'Metadata')
      formData.append('collection', collection)
      this.$api.post(`media/upload/metadata/${this.$route.params.model}`, formData, {
        'Content-Type': 'multipart/form-data'
      })
        .then((res) => {
          this.item.content.images[collection] = res.data
        })
    },

    removeImage (collection) {
      this.$api.delete(`media/${collection}`)
        .then(() => {
          this.item.content.images[collection] = null
        })
    },

    setupImages () {
      if (!this.item.content.images) {
        this.$set(this.item.content, 'images', [])
      }
    }
  }
}
