<template>
  <div class="contents space-y-2">
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.title"
        prop="title"
        name="title"
        label="Title"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.content"
        prop="content"
        name="content"
        label="Content"
        type="markdown"
        rows="10"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.contact_name_show"
        prop="contact_name_show"
        name="contact_name_show"
        label="Show Name Field"
        type="checkbox"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.contact_email_show"
        prop="contact_email_show"
        name="contact_email_show"
        label="Show E-mail Field"
        type="checkbox"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.contact_phone_show"
        prop="contact_phone_show"
        name="contact_phone_show"
        label="Show Phone Field"
        type="checkbox"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.contact_message_show"
        prop="contact_message_show"
        name="contact_message_show"
        label="Show Message Field"
        type="checkbox"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.side"
        :options="[
          {
            key: 'left',
            label: 'Content on left'
          },
          {
            key: 'right',
            label: 'Content on right'
          }
        ]"
        labelKey="label"
        valueKey="key"
        prop="side"
        name="side"
        label="Direction"
        type="select"
      />
    </div>
    <div class="w-full md:w-1/2 px-2">
      <vue-input-group
        v-model="item.content.width"
        prop="width"
        name="width"
        label="Width"
      />
    </div>
    <div class="w-full px-2">
      <vue-data
        :model="item"
        :prop="`background_image_${item.key}`"
        :value="item.content.images[`background_image_${item.key}`]"
        :edit="true"
        accept="image/png, image/jpeg, image/gif"
        label="Background Image"
        type="file"
        @update="syncImage($event, `background_image_${item.key}`)"
        @discard="removeImage(`background_image_${item.key}`)"
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content[`background_image_${item.key}_alt`]"
        :prop="`background_image_${item.key}_alt`"
        :name="`background_image_${item.key}_alt`"
        label="Background Alt Text"
      />
    </div>
  </div>
</template>
<script>
import HasImageUpload from '../../../mixins/HasImageUpload'

export default {
  mixins: [
    HasImageUpload
  ],

  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
